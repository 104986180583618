export const daysFullLoklaiseKeys = { Sunday: 'sunday',
  Monday: 'monday',
  Tuesday: 'tuesday',
  Wednesday: 'wednesday',
  Thursday: 'thursday',
  Friday: 'friday',
  Saturday: 'saturday' };

export const daysShortLoklaiseKeys = { Sun: 'sun',
  Mon: 'mon',
  Tue: 'tue',
  Wed: 'wed',
  Thu: 'thu',
  Fri: 'fri',
  Sat: 'sat' };

export const monthsFullLokaliseKeys = {
  January: 'january',
  February: 'february',
  March: 'march',
  April: 'april',
  May: 'may',
  June: 'june',
  July: 'july',
  August: 'august',
  September: 'september',
  October: 'october',
  November: 'november',
  December: 'december'
};
export const monthsShortLokaliseKeys = { Jan: 'jan',
  Feb: 'feb',
  Mar: 'mar',
  Apr: 'apr',
  May: 'may',
  Jun: 'jun',
  Jul: 'jul',
  Aug: 'aug',
  Sep: 'sep',
  Oct: 'oct',
  Nov: 'nov',
  Dec: 'dec' };

export const alignerSetButtonTypes = {
  CURRENT: 'current',
  PREVIOUS: 'previous',
  FUTURE: 'future',
  FUTURE_MANDATORY_REVIEW: 'future-mandatory-review',
  FUTURE_EOT: 'future-eot'
};

export const appointmentCardTheme = {
  RED: 'red-border',
  GREEN: 'green-border',
  BLUE: 'blue-border',
  GRAY: 'gray-border'
};

export const appointmentCardTypes = {
  DEFAULT: 'DEFAULT',
  NO_SHOW_VISIT_OVERDUE: 'NO_SHOW_VISIT_OVERDUE',
  VISIT_OVERDUE: 'VISIT_OVERDUE',
  NOT_SCHEDULED: 'NOT_SCHEDULED',
  SCHEDULED: 'SCHEDULED',
  CONFIRMATION: 'CONFIRMATION',
  CONFIRMED_BY_CLINIC: 'CONFIRMED_BY_CLINIC',
  COMPLETED: 'COMPLETED',
  NO_SHOW: 'NO_SHOW',
  NO_SHOW_RESCHEDULED: 'NO_SHOW_RESCHEDULED'
};

export const appointmentTitleMapping = {
  FITTING: 'fitting',
  RE_SCAN: 're_scan',
  ADD_ATTACHMENT: 'add_attachment',
  REMOVE_ATTACHMENT: 'remove_attachment',
  IPR_STAGE: 'ipr_stage',
  REVIEW_100: 'review_100',
  MID_REVIEW: 'mid_review',
  EXTRACTION: 'extraction',
  ADD_BUTTON: 'add_button',
  REMOVE_BUTTON: 'remove_button',
  RE_CHECK: 're_check',
  SCAN: 'scan',
  CONSULTATION: 'consultation'
};

export const appointmentTypes = {
  FITTING: 'FITTING',
  IPR_STAGE: 'IPR_STAGE',
  REVIEW_100: 'REVIEW_100',
  MID_REVIEW: 'MID_REVIEW',
  EXTRACTION: 'EXTRACTION',
  RE_SCAN: 'RE_SCAN',
  RE_CHECK: 'RE_CHECK',
  SCAN: 'SCAN'
};

export const appointmentTypeDetailsKeys = {
  IPR_STAGE: 'ipr_type_details',
  MID_REVIEW: 'mid_review_type_details'
};

export const warningStatuses = [appointmentCardTypes.NO_SHOW_VISIT_OVERDUE,
  appointmentCardTypes.VISIT_OVERDUE, appointmentCardTypes.NOT_SCHEDULED];

export const cautionStatuses = [appointmentCardTypes.CONFIRMED_BY_CLINIC];

export const intercomeDisabledPages = ['login', 'camera'];
export const whatsappEnabledPages = ['sales', 'treatment-plan-sales'];

export const preTreatmentStages = ['PENDING', 'ALIGNERS_ORDERED'];

export const excludedTreatmentStatuses = ['INITIATED'];

export const treatmentTypes = {
  REFINEMENT: 'refinement',
  AFTER_SALES: 'aftersales'
};

export const setAppointmentTypes = ['IPR_STAGE', 'ADD_ATTACHMENT', 'REMOVE_ATTACHMENT',
  'ADD_BUTTON', 'REMOVE_BUTTON', 'REVIEW_100'];

export const treatmentKeysConfig = {
  MY_TREATMENT: 'my_treatment',
  REFINEMENT_INFO: 'refinement_info',
  REFINEMENT: 'refinement',
  AFTER_SALES: 'AFTER_SALES'
};

export const RETAINER_KEY = 'retainer';

export const otpModalHeading = {
  LOGIN: 'login_page.enter_otp',
  TREATMENT_PLAN: 'login_page.confirm_approval'
};

export const PRE_IN_PROGRESS_STATUSES = [
  'INITIATED', 'WAITING_TPV_APPROVAL', 'TPV_APPROVED', 'ALIGNERS_ORDERED', 'PENDING'
];

export const RETAINER_PAYMENT_STATUSES = {
  PAYMENT_DUE: 'PaymentDue',
  IN_PROGRESS: 'InProgress',
  PAID: 'Paid',
  FAILED: 'Failed'
};

export const TPV_SECTIONS_DESKTOP = [
  'Summary',
  'Limitations',
  'Risks',
  'Guidelines'
];

export const TPV_SECTIONS_MOBILE = [
  '3d-Model',
  'Summary',
  'Limitations',
  'Risks',
  'Guidelines',
  'Approval'
];

export const ZENYUM_WHATSAPP = 'https://wa.me/6589073136';
